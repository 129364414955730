<template lang="pug">
	article#medias
		.filtros
			h2(v-if="campaign") MÍDIAS DE {{ campaign.name }}
			h2(v-if="campaign") PASTA {{ folder_name }}
			.opcoes
				Tabs(:tabs="tabs", :active.sync="activeTab")
				.filtro
					.input
						img(:src="require('@images/icons/search.png')")
						input(placeholder="O que está procurando?", v-on:keyup.enter="loadMedias()" v-model="params.q")
					select(v-model="params.media_type" v-if="campaign")._25
						option(:value="null") Categorias
						option(v-for="type in media_types", :value="type.id") {{type.name}}
		ul.cards
			li.card(v-for="media in medias")
				Media(
					:imagem="{thumb: (media.files.thumb_file && media.files.thumb_file.name) ? media.files.thumb_file.url : media.files.media_file.url, file: media.files.media_file.url}"
					:download="true"
					:edit="edit"
					:media_id="media.id"
					:titulo="getTitle(media)"
					:action_id="params.action_id"
				)
</template>

<script>
import Media from '@components/Media/Media'
import Tabs from '@components/Tabs/Tabs'
import Folder from '@components/Folder/Folder'
import qs from "qs"

export default {
	name: "campaign-folder",
	components: {
		Media,
		Tabs,
		Folder
	},
	data() {
		return {
			activeTab: '',
			tabs: [],
			medias: [],
			media_types: [],
			page: 1,
			params: {
				type: null,
				page: 1,
				paginate:1,
				q:'',
				media_type: null,
				campaign_id: null,
				editable: null,
				action_id: null,
				dealer_id: null,
				communication_id: null,
				auth_dealer: null,
				folder_id: null
			},
			campaign: null,
			shouldUpdateMedias: true,
			edit: false,
			openedFolder: false,
			folder_name: ""
		}
	},
	watch: {
		'params.media_type': function (){
			if(this.shouldUpdateMedias){
				this.loadMedias()
				return
			}
			this.shouldUpdateMedias = true;
		},
	},
	mounted() {
		if (this.$route.query.editable) {
			this.params.editable = this.$route.query.editable
			this.edit = true
		}
		if (this.$route.params.campaign_id) {
			this.params.campaign_id = this.$route.params.campaign_id
			this.loadCampaign()
				.then(() => {
					this.loadMedias(this.page)
					this.loadMediaTypes()
				})
		}
		if (this.$route.query.action_id) {
			this.params.action_id = this.$route.query.action_id
		}
		// this.$eventbus.$on('open_folder', (folder) => {
		// 	this.params.folder_id = folder.id;
		// 	this.folder_name = folder.title;
		// 	this.openedFolder = true;
		// 	this.loadMedias();
		// })
		if (this.$route.params.folder_id){
			this.params.folder_id = this.$route.params.folder_id;
			this.folder_name = this.$route.params.folder_name;
			this.loadMedias();
		}
	},
	methods: {
		loadMediaTypes() {
			if(this.params.media_type !== null){
				this.params.media_type = null;
			}
			this.$axios.get(`media-types?type=${this.params.type}`)
				.then(response => {
					this.media_types = response.data;
				})
		},
		loadMedias(page = 1) {
			this.params.type = this.activeTab;
			this.params.page = page;
			this.params.auth_dealer = this.$auth.user.dealer_id;
			this.$axios.get(`medias?${qs.stringify(this.params)}`)
				.then(response => {
					if(response.data.data)
						this.medias = response.data.data;
					else
						this.medias = response.data;
				})
				.catch(() => {
					this.$router.replace({name: 'home'})
				});
		},
		async loadCampaign() {
			return await this.$axios.get(`campaigns/${this.params.campaign_id}`)
				.then(response => {
					if(response.data.data)
						this.campaign = response.data.data;
					else
						this.campaign = response.data;
					this.tabs = [this.campaign.type]
					this.activeTab = this.campaign.type
					this.params.type = this.campaign.type
				});
		},
		getTitle(media){
			if (media.media_type)
				return media.media_type + '<br>' + (media.alt_text != null ? media.alt_text : ' ')
			else
				return (media.alt_text != null ? media.alt_text : ' ')
		}
	},
}
</script>

<style lang="stylus" scoped src="./CampaignFolder.styl"></style>
